<template>
	<div class="heading-container">
		<TnHeading
			type="h2"
			size="s"
			responsive
			class="margin-bottom-xl"
			>{{ heading }}</TnHeading
		>
		<LinkInline
			v-if="topLink"
			v-bind="topLink"
			:class="{ linkRight }"
			chevronRight
		/>
		<UspLinks
			v-if="uspLinks"
			:links="uspLinks"
			class="icon-list"
		/>
	</div>
</template>

<script>
export default defineNuxtComponent({
	props: {
		heading: {
			type: String,
		},
		topLink: {
			type: Object,
		},
		uspLinks: {
			type: Array,
		},
		linkRight: {
			type: Boolean,
		},
	},
});
</script>

<style lang="scss" scoped>
.heading-container {
	display: flex;
	align-items: baseline;

	.linkRight {
		margin-left: auto;
	}

	.link {
		padding-left: $spacing-m;
	}

	.icon-list {
		margin-left: auto;
	}
}
</style>
