<template>
	<ul class="icon-list">
		<li
			v-for="(link, index) in links"
			:key="`usplink-${index}`"
		>
			<NuxtImg
				:src="link.icon.src"
				:alt="link.icon.alt"
				loading="lazy"
				class="icon"
			/>
			<span class="text">{{ link.text }}</span>
		</li>
	</ul>
</template>

<script>
export default defineNuxtComponent({
	props: {
		links: {
			type: Array,
		},
	},
});
</script>

<style lang="scss" scoped>
.icon-list {
	display: inline-block;
	list-style: none;
	padding-left: 0;
	text-align: right;
	float: right;

	@include font-text-bold-xs;

	li {
		display: inline-block;

		+ li {
			margin-left: $spacing-l;
		}

		.icon {
			display: inline-block;
			width: 21px;
		}

		:deep(img) {
			width: auto;
			height: 21px;
			vertical-align: middle;
		}

		.text {
			padding-left: $spacing-s;
		}
	}

	@media (max-width: $size-screen-s) {
		display: none;
	}
}
</style>
